import { Button, Flex, Heading, Input } from "@chakra-ui/react";
import { useState } from "react";
import { createUserAction } from "../../services/supabase";

interface AddActionProps {
  onSave: () => void;
  onCancel: () => void;
}

export const AddAction = ({onSave, onCancel}: AddActionProps) => {

  const [name, setName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (event: any) => {
    setName(event.target.value);
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      saveAction();
    }
  }

  const saveAction = async () => {
    if (isSaving || !name?.trim().length) {
      return;
    }
    setIsSaving(true);
    try {
      await createUserAction(name);
      onSave();
    } catch (err) {
      console.log(err)
    }
    setIsSaving(false);
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="4"
    >
      <Heading
        size="md"
      >
        Add an Action
      </Heading>
      <Input
        placeholder="Action Name"
        size="lg"
        color="linkedin"
        onKeyDown={handleKeyPress}
        onChange={handleChange}
      />
      <Flex
        flex="1"
        width="full"
        mt="2"
        gap="4"
      >
        <Button
          flexGrow="1"
          size="lg"
          variant="outline"
          colorScheme="linkedin"
          isLoading={isSaving}
          onClick={saveAction}
        >
          Create Action
        </Button>
        <Button
          minW="120px"
          size="lg"
          isDisabled={isSaving}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Flex>
    </Flex>
  )

}