import { Auth } from "@supabase/auth-ui-react"
import { supabase } from "../../services/supabase"
import { ThemeSupa } from "@supabase/auth-ui-shared"

export const LoginForm = () => {

  return (
    <>
      <Auth
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
        providers={['google']}
        socialLayout="horizontal"
        redirectTo={`${process.env.REACT_APP_BASE_URL}`}
      />
    </>
  )

}