import { AddIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, List, ListItem } from "@chakra-ui/react";
import { Action } from "../../services/supabase.models";

interface ActionsListProps {
  actions?: Array<Action>;
  onSelect: (action: Action) => void;
  startCreate: () => void;
}

export const ActionsList = ({actions, onSelect, startCreate}: ActionsListProps) => {

  return (
    <List
      spacing="4"
    >
      {
        actions && actions.map((action) => {
          return (
            <ListItem key={action.id}>
              <ActionListItem
                action={action}
                onSelect={onSelect}
              />
            </ListItem>
          )
        })
      }
      <ListItem>
        <Button
          mt="4"
          leftIcon={<AddIcon />}
          onClick={startCreate}
        >
          Create New Action
        </Button>
      </ListItem>
    </List>
  )

}

interface ActionListItemsProps {
  action: Action;
  onSelect: (action: Action) => void;
}

const ActionListItem = ({action, onSelect}: ActionListItemsProps) => {

  return (
    <Box>
      <Button
        w="full"
        size="lg"
        colorScheme="linkedin"
        variant="outline"
        justifyContent="space-between"
        rightIcon={<ArrowForwardIcon />}
        onClick={() => onSelect(action)}
      >
        {action.name}
      </Button>
    </Box>
  )

}