import { Outlet, useNavigate } from "react-router-dom"
import { useAuth } from "../context/auth";
import { useEffect } from "react";

export const HomeLayout = () => {

  const { user } = useAuth();
  
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/app');
    }
  }, [user])

  return (
    <Outlet />
  )

}