import { Box, Slide, useColorModeValue, useTheme } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface NewActionSlideProps {
  isVisible?: boolean;
}

export const NewActionSlide = ({children, isVisible}: PropsWithChildren<NewActionSlideProps>) => {

  const theme = useTheme()
  const bgColorKey = useColorModeValue('_light', '_dark');

  return (
    <Slide
      direction='bottom'
      in={isVisible}
      style={
        {
          position: 'absolute',
          height: '100%',
          zIndex: 11
        }
      }
    >
      <Box
        p='4'
        bg={theme.semanticTokens.colors['chakra-body-bg'][bgColorKey]}
        height="full"
      >
        {children}
      </Box>
    </Slide>
  )

}