import { useEffect, useState } from "react";
import { Act, Action } from "../../services/supabase.models";
import { ActionChart } from "./action-chart";

interface ActionInfoProps {
  action: Action;
  acts: Array<Act>;
}

export const ActionInfo = ({action, acts}: ActionInfoProps) => {

  const getActs = () => {
    const filteredActs = acts.filter((actionAct) => {
      return actionAct.action_id === action.id;
    })
    return filteredActs;
  }

  const [actionActs, setActionActs] = useState<Array<Act>>(getActs());

  const updateActs = () => {
    setActionActs(getActs());
  }

  useEffect(() => {
    updateActs();
  }, [acts])

  return (
    <>
      {
        acts &&
          <ActionChart
            action={action}
            acts={actionActs}
          />
      }
    </>
  )

}