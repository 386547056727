import { createClient } from '@supabase/supabase-js';
import { Act, Action, InsertParams, TableKey } from './supabase.models';
import { Database, Tables } from './supabase.types';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL as string;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY as string;

interface TableOrderOpts {
  column: string,
  options?: { ascending?: boolean }
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);

// Auth

export const logOut = async () => {
  await supabase.auth.signOut();
}

// Utilities

export const fetchSupbaseData = async <T extends TableKey>(
  table: T,
  order?: TableOrderOpts
) => {
  const user = await supabase.auth.getUser();
  if (!user?.data?.user) {
    throw new Error('No logged in user!')
  }

  let query = supabase
    .from(table)
    .select()
    .eq('user_id', user.data.user?.id)
  
  if (order) {
    query = query.order(order.column, order.options);
  }

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return data as Array<Tables<T>>;
}

export const insertSupabaseData = async <T extends TableKey>(
  table: T,
  data: InsertParams<T>
): Promise<void> => {
  const user = await supabase.auth.getUser();
  if (!user?.data?.user) {
    throw new Error('No logged in user!')
  }

  const insertData: any = {
    ...data,
    user_id: user.data.user.id
  }

  const { error } = await supabase
    .from(table)
    .insert(insertData)
  if (error) {
    throw new Error(error.message);
  }
}

export const deleteSupabaseData = async <T extends TableKey>(table: T, id: number) => {
  await supabase.from(table).delete().eq('id', id);
}

// Functions

// Actions

export const getUserActions = async (): Promise<Array<Action>> => {
  const result = await fetchSupbaseData('actions', {
    column: 'created_at',
    options: {
      ascending: false
    }
  });
  return result;
}

export const createUserAction = async (name: string) => {
  const data: InsertParams<'actions'> = {
    name: name
  }
  await insertSupabaseData('actions', data);
}

export const deleteUserAction = async (action: Action) => {
  await deleteSupabaseData('actions', action.id);
}

// Acts

export const getUserActs = async (): Promise<Array<Act>> => {
  const result = await fetchSupbaseData('acts', {
    column: 'created_at',
    options: {
      ascending: false
    }
  });
  return result;
}

// export const getUserActsByAction = async (actionId: string): Promise<Array<Act>> => {
//   const result = await fetchSupbaseData('acts', {
//     column: 'created_at',
//     options: {
//       ascending: false
//     }
//   });
//   return result;
// }

export const createUserAct = async (actionId: number, count: number) => {
  const data: InsertParams<'acts'> = {
    action_id: actionId,
    count: count
  }
  await insertSupabaseData('acts', data);
}
