import { Box, Slide, useColorModeValue, useTheme } from "@chakra-ui/react"
import { PropsWithChildren, RefObject } from "react";
import { Action } from "../../services/supabase.models";

interface ActionSlideProps {
  innerRef: RefObject<HTMLDivElement>;
  action?: Action;
}

export const ActionSlide = ({children, action, innerRef}: PropsWithChildren<ActionSlideProps>) => {

  const theme = useTheme()
  const bgColorKey = useColorModeValue('_light', '_dark');

  return (
    <Slide
      ref={innerRef}
      direction='bottom'
      in={action ? true : false}
      style={
        {
          position: 'absolute',
          zIndex: 10
        }
      }
    >
      <Box
        p='4'
        bg={theme.semanticTokens.colors['chakra-body-bg'][bgColorKey]}
        height="full"
      >
        {children}
      </Box>
    </Slide>
  )

}