import { Flex } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface AppBoxProps {
  style?: any;
  size: 'small' | 'medium' | 'large' | 'full'
}

export const AppBox = ({children, size, style}: PropsWithChildren<AppBoxProps>) => {

  const maxWidthMap = {
    small: '33%',
    medium: '50%',
    large: '67%',
    full: '100%',
  }

  return (
    <Flex
      flexDirection="column"
      p={4}
      shadow='md'
      borderWidth='1px'
      w="full"
      style={style}
      maxW={{
        base: '100%',
        md: maxWidthMap[size],
        lg: maxWidthMap[size]
      }}
    >
      {children}
    </Flex>
  )

}