import { AuthProvider } from "./context/auth";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import { HomeLayout } from "./layouts/home";
import { HomePage } from "./pages/home/home";
import { NoMatchPage } from "./pages/no-match";
import { AuthLoginPage } from "./pages/auth/login";
import { AppLayout } from "./layouts/app";
import { AppHomePage } from "./pages/app/app";
import theme from "./theme";

function App() {
  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<HomeLayout />}>
              <Route index element={<HomePage />} />
              <Route path="*" element={<NoMatchPage />} />
            </Route>
            <Route path="/auth" element={<HomeLayout />}>
              <Route path="login" element={<AuthLoginPage />} />
            </Route>
            <Route path="/app" element={<AppLayout />}>
              <Route path="/app/:actionId?" element={<AppHomePage />} />
            </Route>
          </Routes>
        </Router>
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;
