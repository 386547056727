import { Box, Flex, Heading, Image } from "@chakra-ui/react"
import { LoginForm } from "../../components/auth/login"

export const AuthLoginPage = () => {

  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <Box
        m={2}
        w={"full"}
        maxW={['full', null, 'lg']}
        position="relative"
        height="100vh"
      >
        <Flex
          position="relative"
          flexDirection="column"
          w="full"
          top="8"
          gap="8"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="full"
            gap="4"
          >
            <Image
              src="/images/logo.png"
              boxSize='100px'
              objectFit='cover'
            />
            <Heading size="lg">
              Count Tracker
            </Heading>
            <Heading size="sm">
              Log In
            </Heading>
          </Flex>
          <LoginForm />
        </Flex>
      </Box>
    </Flex>
  )

}