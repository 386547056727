import { ArrowBackIcon, ArrowLeftIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Flex, Heading, IconButton, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { motion } from 'framer-motion';
import { Action } from "../../services/supabase.models";
import { createUserAct } from "../../services/supabase";

interface TrackActionProps {
  action?: Action;
  onBack: () => void;
  onDelete: (action: Action) => Promise<void>;
  onCreateAct: () => Promise<void>;
}

export const TrackAction = ({action, onBack, onDelete, onCreateAct}: TrackActionProps) => {

  const [value, setValue] = useState<number | undefined>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInput = async (number: number) => {
    if (number >= 0) {
      handleNumberInput(number);
    }
    if (number === -1) {
      handleInputDelete();
    }
    if (number === -2) {
      await handleSubmitInput();
    }
  }

  const handleNumberInput = (number: number) => {
    if (!value && number === 0) {
      return;
    }
    let newValueString = '';
    if (value) {
      newValueString = `${value}`;
    }
    newValueString += `${number}`
    setValue(parseInt(newValueString));
  }
  
  const handleInputDelete = () => {
    if (!value) {
      return;
    }
    const currentValueString = `${value}`;
    if (currentValueString.length === 1) {
      setValue(undefined);
      return;
    }
    const newValueString = currentValueString.substring(0, currentValueString.length - 1);
    setValue(parseInt(newValueString));
  }
  
  const handleSubmitInput = async () => {
    if (isSubmitting || !action || !value) {
      return;
    }
    setIsSubmitting(true);
    try {
      await createUserAct(action.id, value);
      await onCreateAct();
      setIsSubmitted(true);

      setTimeout(() => {
        setIsSubmitted(false);
        setValue(undefined);
        setIsSubmitting(false);
      }, 300);
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
    }
  }

  const startDelete = () => {
    setIsDeleting(true);
  }

  const closeDelete = () => {
    setIsDeleting(false);
  }

  const handleDelete = async () => {
    if (action) {
      onDelete(action)
    }
  }

  return (
    <Flex
      flexDirection="column"
      gap="8"
    >
      <Flex
        justifyContent="space-between"
      >
        <Box
          style={{
            width: '40px',
            height: '40px',
          }}
        >
          <IconButton
            icon={<ArrowBackIcon />}
            aria-label="Back to actions"
            onClick={onBack}
          />
        </Box>
        <Flex
          w="full"
          h="40px"
          px="50px"
          alignItems="center"
          justifyContent="center"
        >
          <Heading size="md">
            {action?.name}
          </Heading>
        </Flex>
        <Box
          style={{
            width: '40px',
            height: '40px',
          }}
        >
          <IconButton
            icon={<DeleteIcon />}
            variant='ghost'
            aria-label="Delete action"
            onClick={startDelete}
          />
          <TrackActionDelete
            isOpen={isDeleting}
            onClose={closeDelete}
            onDelete={handleDelete}
          />
        </Box>
      </Flex>
      <Box
        w="full"
      >
        <TrackActionInputDisplay
          value={value}
          isSubmitted={isSubmitted}
        />
      </Box>
      <Box
        w="full"
      >
        <TrackActionInput
          onInput={handleInput}
          hasValue={value && value > 0 ? true : false}
          isSubmitting={isSubmitting}
        />
      </Box>
    </Flex>
  )

}

interface TrackActionDeleteProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const TrackActionDelete = ({isOpen, onClose, onDelete}: TrackActionDeleteProps) => {

  const cancelRef = useRef<HTMLButtonElement>(null);

  const confirmDelete = async () => {
    onDelete();
    onClose();
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Delete Action
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='red' onClick={confirmDelete} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )

}

interface TrackActionInputProps {
  hasValue: boolean;
  isSubmitting: boolean;
  onInput: (number: number) => Promise<void>;
}

const TrackActionInput = ({onInput, hasValue, isSubmitting}: TrackActionInputProps) => {

  const buttonList = [1, 2, 3, 4, 5, 6, 7, 8, 9, -1, 0, -2];

  return (
    <Flex
      w="full"
      flexGrow="1"
    >
      <SimpleGrid w="full" columns={3} spacing={10}>
        {
          buttonList.map((number, index) => {
            return (
              <TrackActionInputButton
                key={index}
                number={number}
                onInput={onInput}
                hasValue={hasValue}
                isSubmitting={isSubmitting}
              />
            )
          })
        }
      </SimpleGrid>
    </Flex>
  )

}

interface TrackActionInputButtonProps {
  number: number;
  hasValue: boolean;
  isSubmitting: boolean;
  onInput: (number: number) => Promise<void>;
}

const TrackActionInputButton = ({number, hasValue, isSubmitting, onInput}: TrackActionInputButtonProps) => {

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
    >
      {
        number >= 0 &&
          <IconButton
            isRound={true}
            aria-label={`${number} button`}
            style={{
              height: '4rem',
              width: '4rem'
            }}
            icon={
              <Heading size="lg">
                {number}
              </Heading>
            }
            onClick={() => onInput(number)}
          />
      }
      {
        number === -1 &&
          <IconButton
            isRound={true}
            aria-label="delete button"
            style={{
              height: '4rem',
              width: '4rem'
            }}
            icon={<ArrowLeftIcon fontSize="24" />}
            onClick={() => onInput(number)}
          />
      }
      {
        number === -2 && hasValue &&
          <IconButton
            isRound={true}
            aria-label="submit button"
            colorScheme="whatsapp"
            style={{
              height: '4rem',
              width: '4rem'
            }}
            icon={<CheckIcon fontSize="24" />}
            isLoading={isSubmitting}
            onClick={() => onInput(number)}
          />
      }
    </Flex>
  )

}

interface TrackActionInputDisplayProps {
  value?: number;
  isSubmitted: boolean;
}

const TrackActionInputDisplay = ({value, isSubmitted}: TrackActionInputDisplayProps) => {

  const valueColor = useColorModeValue('var(--chakra-colors-blue-500)', 'var(--chakra-colors-cyan-300)');

  const MotionText = motion(Text);

  return (
    <Flex
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      {
        value &&
          <MotionText
            fontSize='2xl'
            as='b'
            color={valueColor}
            animate={isSubmitted ? {
              scale: [1, 1.4, 1],
              rotate: [0, 15, -15, 0],
              color: [valueColor, 'var(--chakra-colors-whatsapp-400)']
            } : {}}
            transition={{duration: 0.3}}
          >
            {value}
          </MotionText>
      }
      {
        !value &&
          <Text
            fontSize='2xl'
            as='i'
            color="gray"
          >
            Enter Count
          </Text>
      }
    </Flex>
  )

}
