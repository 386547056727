import { Box, Flex, Heading } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useResizeObserver } from "usehooks-ts"
import { ActionInfo } from "../../components/actions/action-info"
import { ActionSlide } from "../../components/actions/action-slide"
import { ActionsList } from "../../components/actions/actions-list"
import { AddAction } from "../../components/actions/add-action"
import { NewActionSlide } from "../../components/actions/new-action-slide"
import { TrackAction } from "../../components/actions/track-action"
import { AppBox } from "../../components/shared/app-box"
import { useAuth } from "../../context/auth"
import { deleteUserAction, getUserActions, getUserActs } from "../../services/supabase"
import { Act, Action } from "../../services/supabase.models"

export const AppHomePage = () => {

  const navigate = useNavigate();

  const {user} = useAuth();
  const params = useParams();
  const selectedActionId = params.actionId;

  const [actions, setActions] = useState<Array<Action>>();
  const [selectedAction, setSelectedAction] = useState<Action | undefined>();

  const [acts, setActs] = useState<Array<Act>>();

  const [isCreatingAction, setIsCreatingAction] = useState(false);

  const [actionBoxHeight, setActionBoxHeight] = useState(0);

  const actionBoxRef = useRef<HTMLDivElement>(null)
  const actionBoxDimensions = useResizeObserver({
    ref: actionBoxRef,
    box: 'content-box',
  })

  useEffect(() => {
    if (user && !actions) {
      getActions();
    }
    if (user && !acts) {
      getActs();
    }
  }, [user])

  useEffect(() => {
    if (actionBoxDimensions?.height) {
      setActionBoxHeight(actionBoxDimensions.height);
    }
  }, [actionBoxDimensions])

  useEffect(() => {
    if (actions && selectedActionId) {
      updateSelectedAction(parseInt(selectedActionId));
    }
  }, [selectedActionId])

  useEffect(() => {
    if (actions && !selectedAction && selectedActionId) {
      updateSelectedAction(parseInt(selectedActionId));
    }
  }, [actions])

  const getActions = async () => {
    const result = await getUserActions();
    setActions(result);
  }

  const getActs = async () => {
    const result: Array<Act> = await getUserActs();
    setActs(result);
  }

  const updateSelectedAction = (actionId: number) => {
    if (!actions) {
      return;
    }
    const action = actions.find((actionItem) => {
      return actionItem.id === actionId;
    })
    if (!action) {
      throw new Error('Could not find the selected action!');
    }
    setSelectedAction(action);
  }

  const onSelectAction = (action: Action) => {
    navigate(`/app/${action.id}`)
  }

  const onDeselectAction = () => {
    setSelectedAction(undefined);
    navigate('/app')
  }

  const onSaveAction = () => {
    setIsCreatingAction(false);
    getActions();
  }

  const onDeleteAction = async (action: Action) => {
    await deleteUserAction(action);
    onDeselectAction();
    getActions();
  }

  return (
    <Box>
      <Flex
        flexDirection={{
          'base': 'column',
          'md': 'row',
          'lg': 'row'
        }}
        gap="8"
      >
        <AppBox
          size="small"
          style={{
            position: 'relative',
            overflow: 'hidden',
            height: `${actionBoxHeight}px`
          }}
        >
          <Box
            overflowY="scroll"
            position="relative"
            height="full"
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
              },
              scrollbarWidth: 'none', // Hide scrollbar for Firefox
              '-ms-overflow-style': 'none',  // Hide scrollbar for IE and Edge
            }}
          >
            <Heading
              fontSize='xl'
              mb="4"
            >
              Select an Action
            </Heading>
            <ActionsList
              onSelect={onSelectAction}
              startCreate={() => setIsCreatingAction(true)}
              actions={actions}
            />
          </Box>
          <ActionSlide
            innerRef={actionBoxRef}
            action={selectedAction}
          >
            <TrackAction
              action={selectedAction}
              onBack={onDeselectAction}
              onDelete={onDeleteAction}
              onCreateAct={getActs}
            />
          </ActionSlide>
          <NewActionSlide
            isVisible={isCreatingAction}
          >
            <AddAction
              onSave={onSaveAction}
              onCancel={() => setIsCreatingAction(false)}
            />
          </NewActionSlide>
        </AppBox>
        <AppBox size="large">
          {
            !selectedAction &&
              <Flex
                justifyContent="center"
                alignItems="center"
                h="full"
              >
                <Heading fontSize='xl'>
                  Select or create an action
                </Heading>
              </Flex>
          }
          {
            selectedAction &&
              <Flex
                flexDirection="column"
                gap="4"
                flex="1"
              >
                <Heading fontSize='xl'>
                  Trends for {selectedAction.name}
                </Heading>
                {
                  acts &&
                    <ActionInfo
                      action={selectedAction}
                      acts={acts}
                    />
                }
              </Flex>
          }
        </AppBox>
      </Flex>
    </Box>
  )

}