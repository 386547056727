import { Box, Button, ButtonGroup, Flex, useColorModeValue } from "@chakra-ui/react";
import { Act, Action } from "../../services/supabase.models";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

interface ActionChartProps {
  action: Action;
  acts: Array<Act>;
}

enum Timeline {
  DAYS = 'day',
  WEEKS = 'week',
  MONTHS = 'month'
}

const TIME_UNITS_SHOWN = 5;

export const ActionChart = ({action, acts}: ActionChartProps) => {

  const defaultTimeline = Timeline.DAYS;

  const [timeline, setTimeline] = useState<Timeline>(defaultTimeline);

  useEffect(() => {
    updateData();
  }, [acts])

  const getStartDate = () => {
    return DateTime.now().minus({[timeline]: TIME_UNITS_SHOWN - 1}).startOf(timeline);
  }

  const getSeriesData = () => {
    const startDate = getStartDate();
    const activeActs = acts.filter((act) => {
      return DateTime.fromISO(act.created_at) >= startDate;
    })
    return activeActs;
  }

  const groupSeriesData = () => {
    const activeActs = getSeriesData();
    const startDate = getStartDate();
    const seriesData: Array<number> = [];
    for (let index = 0; index < TIME_UNITS_SHOWN; index++) {
      seriesData.push(0);
    }
    activeActs.forEach((act) => {
      const actDate = DateTime.fromISO(act.created_at);
      const timeDelta = actDate.diff(startDate, `${timeline}s`);
      const timeSinceAct = timeDelta.toObject()[`${timeline}s`] as number;
      const seriesIndex = Math.floor(timeSinceAct);
      if (seriesIndex < 0 || seriesIndex > seriesData.length) {
        return;
      }
      seriesData[seriesIndex] += act.count;
    })
    return seriesData;
  }

  const getXAxisCategories = () => {
    const startDate = getStartDate();
    const categories: Array<string> = [];
    for (let index = 0; index < TIME_UNITS_SHOWN; index++) {
      const categoryDate = startDate.plus({[timeline]: index});
      if (timeline === Timeline.DAYS) {
        categories.push(categoryDate.weekdayLong);
      }
      if (timeline === Timeline.WEEKS) {
        categories.push(categoryDate.toFormat('MM/dd'));
      }
      if (timeline === Timeline.MONTHS) {
        categories.push(categoryDate.toFormat('MMM'));
      }
    }
    return categories;
  }

  const [categories, setCategories] = useState<Array<string>>(getXAxisCategories());
  const [data, setData] = useState<Array<number>>(groupSeriesData());

  const updateCategories = () => {
    setCategories(getXAxisCategories());
  }
  
  const updateData = () => {
    setData(groupSeriesData());
  }

  useEffect(() => {
    updateCategories();
    updateData();
  }, [timeline])

  const graphColor = useColorModeValue('blue-600', 'blue-500');
  const axisLabelsColor = useColorModeValue('black', 'white');


  const getState = () => {
    return {
      options: {
        chart: {
          id: `${action.id}-${timeline}-acts`,
          fontFamily: 'var(--chakra-fonts-heading)',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          style: {
            fontSize: '18px'
          }
        },
        grid: {
          show: false
        },
        colors: [`var(--chakra-colors-${graphColor})`],
        xaxis: {
          categories: categories,
          labels: {
            style: {
              colors: `var(--chakra-colors-${axisLabelsColor})`,
              fontSize: '14px'
            }
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        tooltip: {
          enabled: false
        }
      },
      series: [{
        name: 'acts',
        type: 'bar',
        data: data,
        stroke: {
          curve: 'straight',
        }
      }]
    }
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex="1"
      height="100%"
      w="full"
      gap="4"
    >
      <Box
        w="full"
      >
        <ActionChartTimelines
          activeTimeline={timeline}
          onClick={(selectedTimeline) => setTimeline(selectedTimeline)}
        />
      </Box>
      <Box w="full">
        <ReactApexChart
          options={getState().options}
          series={getState().series}
          type="bar"
          height={320}
        />
      </Box>
    </Flex>
  )

}

interface ActionChartTimelinesProps {
  activeTimeline: Timeline;
  onClick: (timeline: Timeline) => void;
}

const ActionChartTimelines = ({activeTimeline, onClick}: ActionChartTimelinesProps) => {

  const timelinesButtons = [
    {
      key: Timeline.DAYS,
      label: 'Days'
    },
    {
      key: Timeline.WEEKS,
      label: 'Weeks'
    },
    {
      key: Timeline.MONTHS,
      label: 'Months'
    }
  ]

  return (
    <ButtonGroup size='sm' isAttached variant='outline'>
      {
        timelinesButtons.map((button) => {
          const isActive = button.key === activeTimeline;
          return (
            <Button
              key={button.key}
              colorScheme={isActive ? 'linkedin' : undefined}
              onClick={() => onClick(button.key)}
            >
              {button.label}
            </Button>
          )
        })
      }
    </ButtonGroup>
  )

}

