import { Button, Flex } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

export const HomePage = () => {

  const navigate = useNavigate();

  const gotoLogin = () => {
    navigate('/auth/login');
  }

  return (
    <Flex
      w="full"
      mt="8"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        onClick={gotoLogin}
      >
        Log In
      </Button>
    </Flex>
  )

}