import { MoonIcon, SunIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, IconButton, Image, Tooltip, useColorMode } from "@chakra-ui/react"
import { Outlet, useNavigate } from "react-router-dom"
import { logOut, supabase } from "../services/supabase"

export const AppLayout = () => {

  const naviage = useNavigate();

  supabase.auth.onAuthStateChange((event: string) => {
    if (event === 'SIGNED_OUT') {
      naviage('/');
    }
  })

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      mx="4"
      gap="4"
    >
      <AppLayoutHeader />
      <Box w="full" maxW="7xl">
        <Outlet />
      </Box>
    </Flex>
  )

}

const AppLayoutHeader = () => {

  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box w="full" maxW="7xl">
      <Flex
        h="80px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Image
            src="/images/logo.png"
            h="60px"
          />
        </Box>
        <Flex
          gap="4"
        >
          <Tooltip
            hasArrow
            label={colorMode === 'dark' ? 'Light Mode' : 'Dark Mode'}
          >
            <IconButton
              aria-label='Dark/Light Mode Toggle'
              icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
              onClick={toggleColorMode}
            />
          </Tooltip>
          <Button
            onClick={logOut}
          >
            Sign Out
          </Button>
        </Flex>
      </Flex>
    </Box>
  )

}